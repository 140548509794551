<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="dialogue-box has-header d-flex justify-content-center">
          <div class="dialogue-box-header mx-auto">Change Password</div>
          <div class="dialogue-box-inner px-5 pb-5 w-100">
            <form action="" @submit.prevent="changePassword">
              <div class="row mb-3">
                <div class="col-12">
                  <label for="current_password">Current Password</label>
                  <input type="password" v-model="changePasswordForm.current_password" id="current_password"
                         class="form-control"
                         :class="{'border-danger': changePasswordForm.errors.current_password}">
                  <div class="text-danger small w-100" v-if="changePasswordForm.errors.current_password">{{ changePasswordForm.errors.current_password }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label for="new_password">New password</label>
                  <input type="password" v-model="changePasswordForm.new_password" id="new_password" class="form-control"
                         :class="{'border-danger': changePasswordForm.errors.new_password}">
                  <div class="text-danger small w-100" v-if="changePasswordForm.errors.new_password">{{ changePasswordForm.errors.new_password }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label for="new_password_confirm">Confirm new password</label>
                  <input type="password" v-model="changePasswordForm.new_password_confirm" id="new_password_confirm"
                         class="form-control"
                         :class="{'border-danger': changePasswordForm.errors.new_password_confirm}">
                  <div class="text-danger small w-100" v-if="changePasswordForm.errors.new_password_confirm">{{ changePasswordForm.errors.new_password_confirm }}</div>
                </div>
              </div>

              <input type="submit" value="Change Password" class="btn btn-primary">
            </form>
          </div>
          <div class="dialogue-bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {omit} from 'lodash'
export default {
  data() {
    return {
      changePasswordForm: {
        current_password: '',
        new_password: '',
        new_password_confirm: '',
        errors: []
      }
    }
  },
  methods: {
    changePassword() {
      this.$socket.emit("auth:change password", omit(this.changePasswordForm, 'errors'));
    }
  },
  sockets: {
    'change password errors'(errors) {
      this.changePasswordForm.errors = errors;
    },
    'change password success'() {
      this.changePasswordForm = {
        current_password: '',
        new_password: '',
        new_password_confirm: '',
        errors: []
      }
    }
  }
}
</script>